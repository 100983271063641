<template>
  <b-card title="Tambah Jenis Ujian ➕">
    <b-form @submit.prevent>
      <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Nama Jenis Ujian"
            label-for="v-nama-jenis-ujian"
          >
            <b-form-input
              id="v-nama-jenis-ujian"
              v-model="data.nama_jenis_ujian"
              placeholder="Nama Jenis Ujian"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Kelas"
            label-for="v-id-kelas"
          >
          <b-form-select
            v-model="data.id_kelas"
            :options="optionKelas"
          />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Status"
            label-for="v-status"
          >
            <b-form-select
            v-model="data.status"
            :options="options_status"
          />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveData()"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        nama_jenis_ujian: '',
        id_kelas: '',
        status: '',
      },
      optionKelas: [],
      options_status: [
        { value: '1', text: 'Aktif' },
        { value: '0', text: 'Tidak Aktif' },
      ],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    saveData() {
      const data = {
        nama_jenis_ujian: this.data.nama_jenis_ujian,
        id_kelas: this.data.id_kelas,
        status: this.data.status,
      }
      this.$http.post('/jenis-ujian', data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'jenis-ujian-index' })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    getData() {
      this.$http.get('kelas').then(response => {
        if (response.data.status) {
          response.data.data.forEach(element => {
            const data = {
              value: element.id,
              text: element.nama_kelas,
            }
            this.optionKelas.push(data)
          })
        }
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
